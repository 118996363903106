import { BoxWrap, BoxTitle } from './style';

export interface IBoxProps {
  title: string;
}

export const Box = ({ title }: IBoxProps) => {
  return (
    <BoxWrap>
      <BoxTitle>{title}</BoxTitle>
    </BoxWrap>
  );
};
